.disclaimerOverlay {
  max-width: unset;
}

.disclaimerOverlayHeader {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
}

.disclaimerOverlayContent {
  white-space: pre-line;
}

[data-color-mode='dark'] * .disclaimerOverlay {
  background-color: var(--v-color-background-secondary);
}
